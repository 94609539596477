import styled from '@emotion/styled'
import React from 'react'
import { media } from '@/styles/media'
import SectionTitle from './SectionTitle'

const SectionTitleWrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 33px;

  ${media.tabletLandscapeDown} {
    margin-bottom: 36px;
  }
`

const Line = styled.div<{ centeredOnMobile: boolean }>`
  width: 100%;
  height: 1px;
  background: #000;

  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;

  ${media.tabletLandscapeDown} {
    ${({ centeredOnMobile }) => (centeredOnMobile ? 'display: none;' : '')}
  }
`

export default function SectionTitleWithLine({
  text,
  secondText = '',
  children = null,
  gap = '16px',
  centeredOnMobile = false,
  bigTextOnMobile = false,
  style = {}
}) {
  return (
    <SectionTitleWrapper style={style}>
      <SectionTitle
        text={text}
        secondText={secondText}
        style={{ flexShrink: '0', textAlign: 'left' }}
        bigTextOnMobile={bigTextOnMobile}
      />

      <Line
        style={{ marginLeft: gap, marginRight: children ? gap : '0' }}
        centeredOnMobile={centeredOnMobile}
      />

      {children || ''}
    </SectionTitleWrapper>
  )
}
